<template>
  <v-container>
    <v-form
      ref="form"
      v-model="is_valid"
    >
      <v-row
        class=""
        style="gap: 10px"
      >
        <div class="flex-grow-1 ma-4">
          <h4 class="mb-4">Promotion Information</h4>
          <div
            class="d-flex flex-column flex-md-row"
            style="gap: 10px"
          >
            <div style="flex: 1 1 0">
              <v-text-field
                v-model="promotion.name"
                outlined
                dense
                :label="$t('promotions.name')"
                :disabled="isFormDisabled"
              ></v-text-field>
              <v-select
                clearable
                v-model="promotion.type"
                :items="promotion_types"
                outlined
                dense
                :label="$t('promotions.type')"
                :disabled="isFormDisabled"
                :rules="[(v) => !!v || 'Required']"
              >
              </v-select>
            </div>
            <div style="flex: 1 1 0">
              <v-text-field
                v-model="promotion.discount"
                :rules="[
                  (v) =>
                    (v > 0 && v <= 100) || 'Value should be between 1 and 100',
                ]"
                outlined
                dense
                :label="$t('promotions.discount')"
                @keypress="NumbersOnly"
                :disabled="isFormDisabled"
              ></v-text-field>
              <v-text-field
                v-model="promotion.max_discount_amount"
                outlined
                dense
                :label="$t('promotions.max_discount_amount')"
                @keypress="NumbersOnly"
              ></v-text-field>
              <!-- -->
            </div>
          </div>
        </div>
        <div
          class="flex-grow-1 my-4 ms-2"
          style="max-width: 400px"
        >
          <h4 class="mb-4">{{ $t("promotions.sales_info") }}</h4>
          <v-select
            multiple
            v-model="promotion.sales_channels"
            outlined
            dense
            chips
            small-chips
            :label="$t('promotions.sales_channel')"
            :items="sales_channels"
            :disabled="isFormDisabled"
            :rules="[(v) => v.length > 0 || 'Required']"
          ></v-select>
          <v-autocomplete
            outlined
            clearable
            dense
            v-model="promotion.hubs"
            :items="hubs"
            item-text="name"
            item-value="id"
            :label="$t('hubs')"
            multiple
            chips
            small-chips
            :loading="is_loading_hubs"
            :disabled="isFormDisabled"
          ></v-autocomplete>
        </div>
      </v-row>
    </v-form>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-model="tab"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab :disabled="loading">
          <v-icon>mdi-format-list-bulleted</v-icon>&nbsp;{{
            this.$t("products_list")
          }}
        </v-tab>
        <v-tab :disabled="loading">
          <v-badge
            v-if="promotionDetails?.products?.length"
            color="green"
            inline
            :content="promotionDetails?.products?.length"
          >
            <v-icon>mdi-sale</v-icon>&nbsp;{{ this.$t("promotions.items") }}
          </v-badge>
          <span v-else>
            <v-icon>mdi-sale</v-icon>&nbsp;{{ this.$t("promotions.items") }}
          </span>
        </v-tab>
        <v-btn
          outlined
          color="primary"
          class="mb-4 me-2"
        >
          <v-icon>mdi-plus</v-icon>&nbsp;{{
            this.$t("promotions.create_button")
          }}
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <products-list-tab
            :validatePromotion="validatePromotion"
            v-if="item.content == 'list'"
          ></products-list-tab>
          <promotion-items-tab
            v-if="item.content == 'items'"
          ></promotion-items-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { NumbersOnly } from "@/helpers/input";
import { debounce } from "lodash";
import promotionsService from "@/store/services/promotions-service";
import { promotionTypes, salesChannels } from "./utils/values";
import moment from "moment";

export default {
  components: {
    ProductsListTab: () => import("./tabs/ProductsListTab.vue"),
    PromotionItemsTab: () => import("./tabs/PromotionItemsTab.vue"),
  },

  computed: {
    ...mapGetters({
      hubs: "hubs/listHubs",
      promotionDetails: "promotions/promotionDetails",
    }),
    promotion: {
      get() {
        return this.$store.state.promotions.promotion;
      },
      set(value) {
        this.$store.commit("promotions/SET_PROMOTION", value);
      },
    },
    isFormDisabled() {
      return !!this.promotion.id;
    },
  },

  methods: {
    NumbersOnly: NumbersOnly,
    search_hub: debounce(function () {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store.dispatch("hubs/list", {}).then(() => {
        this.is_loading_hubs = false;
      });
    }, 1000),

    validatePromotion() {
      this.$refs.form.validate();
      this.promotion.name =
        this.promotion?.name ?? moment().format("DD/MM/YYYY");
      return this.is_valid;
    },
  },
  watch: {},

  data() {
    return {
      tab: null,
      is_valid: false,
      loading: false,
      is_loading_hubs: false,
      sales_channels: salesChannels,
      promotion_types: promotionTypes,
      items: [
        {
          tab: this.$t("products_list"),
          content: "list",
        },
        {
          tab: this.$t("promotions.items"),
          content: "items",
        },
      ],
    };
  },
  async mounted() {
    this.search_hub();
    const promotion_id = this.$route.params.id;
    if (!promotion_id) {
      return;
    }
    const promotionDetails = await promotionsService.getPromotionDetails(
      promotion_id
    );
    const {
      id,
      name,
      discount,
      sales_channels,
      type,
      max_discount_amount,
      hubs,
    } = promotionDetails;
    this.promotion = {
      id,
      name,
      hubs: hubs.map((hub) => hub.id),
      discount,
      sales_channels,
      type,
      max_discount_amount,
    };
    this.$store.commit("promotions/SET_PROMOTION_DETAILS", promotionDetails);
  },
};
</script>